@import "./styles/core";

:root {
  --gray: #161c28;
  --gray-transparent-bs: rgba(22, 28, 40, 0.8);
  --light-gray-transparent: rgba(200, 200, 200, 0.24);
  --light-gray-transparent-bs: rgba(0, 0, 0, 0.2);
  --light-gray-transparent-custom: rgba(200, 200, 200, 0.12);
  --white-transparent: rgba(255, 255, 255, 0.2);
  --light-gray: #c8c8c8;
  --primary-bs: #ff3085;
  --warning-transparent-bs: rgba(250, 153, 23, 0.25);
  --warning-bs: #fa9917;
  --success-transparent-bs: rgba(42, 201, 64, 0.25);
  --sucess-lighter-bs: #8ef49c;
  --inactif-red: #f47474;
  --blue-league: #80adff;
  --red-league: #fbaaab;
  --success-bs: #2ac940;
  --secondary-transparent-bs: rgba(240, 36, 120, 0.25);
  --secondary-bs: #f02478;
  --info-bs: #fe61a2;
  --danger-lighter-bs: #f5a86e;
  --red-transparent-bs: #e9484b4f;
  --white: white;
  --blue-transparent-bs: #0055ff7c;

  --background-image-screen: url("./assets/images/bg-dark.jpg");
  --background-color-screen: rgba(22, 28, 40, 0.8);
  --text-color: #000;
  --text-color-light: #c8c8c8;
  --text-color-medium: #2b2f37;
  --background-color-trans: rgba(22, 28, 40, 0.9);
  --background-color-full: #161c28;
  --box-shadow-color: rgba(255, 255, 255, 0.15);

  --button-grey: rgba(200, 200, 200, 0.24);
  --form-background: rgba(0, 0, 0, 0.2);
  --border-background: rgba(200, 200, 200, 0.12);
  --border-separator: rgba(255, 255, 255, 0.2);
  --level-background: #08090e;
}
@font-face {
  font-family: "DM Sans";
  font-weight: 100; /* Thin */
  src: url("./assets/fonts/DM_Sans/DMSans-Thin.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 200; /* ExtraLight */
  src: url("./assets/fonts/DM_Sans/DMSans-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 300; /* Light */
  src: url("./assets/fonts/DM_Sans/DMSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 400; /* Regular */
  src: url("./assets/fonts/DM_Sans/DMSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 500; /* Medium */
  src: url("./assets/fonts/DM_Sans/DMSans-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 600; /* SemiBold */
  src: url("./assets/fonts/DM_Sans/DMSans-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 700; /* Bold */
  src: url("./assets/fonts/DM_Sans/DMSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 800; /* ExtraBold */
  src: url("./assets/fonts/DM_Sans/DMSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 900; /* Black */
  src: url("./assets/fonts/DM_Sans/DMSans-Black.ttf") format("opentype");
}
/* Fonts */
@font-face {
  font-family: "Dinamit";
  src: url("./assets/fonts/Dinamit/Dinamit Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Dinamit";
  src: url("./assets/fonts/Dinamit/Dinamit Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Dinamit";
  src: url("./assets/fonts/Dinamit/Dinamit Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Dinamit";
  src: url("./assets/fonts/Dinamit/Dinamit Light.otf");
  font-weight: lighter;
}

.main-bs {
  background-image: var(--background-image-screen);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.background-color-screen {
  background-color: var(--background-color-screen);
}
.text-color {
  color: var(--text-color);
}
.text-color-light {
  color: var(--text-color-light);
}
.background-color-trans {
  box-shadow: 0px 8px 16px 0px var(--box-shadow-color);
}
.background-color-full {
  background: var(--background-color-full);
  box-shadow: 0px 8px 16px 0px var(--box-shadow-color);
}

.btn-back-primary {
  background: var(--primary-bs) !important;
  color: var(--white) !important;
}
.btn-back-grey {
  background: var(--button-grey) !important;
  color: var(--white);
}
.border-primary {
  border: 1px solid var(--primary-bs) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-small {
  font-size: 12px;
}
.text-normal {
  font-size: 14px;
}
.text-medium {
  font-size: 16px;
}
.text-semibig {
  font-size: 18px;
}
.text-vbig {
  font-size: 26px;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.mr-16 {
  margin-right: 16px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.pb-32 {
  padding-bottom: 32px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.pt-24 {
  padding-top: 24px !important;
}
.pt-32 {
  padding-top: 32px !important;
}
.pr-12 {
  padding-right: 12px !important;
}
.pl-8 {
  padding-left: 12px !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.pl-18 {
  padding-left: 18px !important;
}
.pl-24 {
  padding-left: 24px !important;
}
.pl-32 {
  padding-left: 32px !important;
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.underline {
  text-decoration: underline;
}
.position-relative {
  position: relative;
}
.button-style {
  padding: 8px 14px;
  text-align: center;
  border: none;
}
.button-style-icon {
  padding: 8px 14px;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.button-style-icon img {
  width: 18px;
}
.separator-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-separator);
}
.width-100 {
  width: 100%;
}
.width-fit-content {
  width: fit-content;
}
.padding-box {
  padding: 18px 20px;
}
button[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
.margin-auto {
  margin: 0 auto;
}
/* Fonts */

/*General*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: "Dinamit", sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

body,
html {
  font-size: 14px;
  font-family: "Dinamit", sans-serif;
}

@media (max-width: 600px) {
  .text-small {
    font-size: 10px;
  }
  .text-normal {
    font-size: 12px;
  }
  .text-medium {
    font-size: 14px;
  }
  .text-semibig {
    font-size: 16px;
  }
  .text-vbig {
    font-size: 22px;
  }
  * {
    font-size: 12px;
  }
  body,
  html {
    font-size: 12px;
  }
  .padding-box {
    padding: 10px 12px;
  }
}

/*p,
span {
  color: var(--light-gray);
}*/
/* Excluye los elementos p y span que están dentro de un contenedor con la clase rich-text */
.rich-text p,
.rich-text span {
  color: inherit; /* o cualquier otro color que desees para estos casos */
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
}
.margins-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.header-content-margin {
  margin-top: 3rem;
}
.lading-padding {
  padding: 3rem 2.5rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
/*General*/

/*FILTERS SECTION*/
.grey-filters-dropdown {
  padding: 0.4rem;
  width: 200px;
}
.black {
  background-color: #161924;
  color: white;
  border: none;
}
.more-filters-btn {
  background-color: transparent;
  border: none;
}
/*FILTERS SECTION*/

/*Circles*/
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.circle-sm {
  width: 5px;
  height: 5px;
}

.circle-warning {
  background-color: var(--warning-bs);
}

.circle-secondary {
  background-color: var(--secondary-bs);
}

.circle-success {
  background-color: var(--success-bs);
}

/*Modal*/
.modal-bs .modal-header,
.modal-bs .modal-footer {
  /*border-color: var(--white-transparent);*/
  border: none;
}

.modal-bs .modal-content {
  background: transparent;
  border-radius: 15px;
  overflow: hidden;
}

/*Backgrounds*/
.bg-primary-bs {
  background-color: var(--primary-bs);
}
.bg-gray {
  background-color: var(--gray);
}
.bg-gray-transparent {
  background-color: var(--gray-transparent-bs);
}
.bg-light-gray-transparent {
  background-color: var(--light-gray-transparent-bs);
}
.bg-warning-transparent {
  background-color: var(--warning-transparent-bs);
}
.bg-success-transparent {
  background-color: var(--success-transparent-bs);
}
.bg-blue-transparent {
  background-color: var(--blue-transparent-bs);
}
.bg-secondary-transparent {
  background-color: var(--secondary-transparent-bs);
}
.bg-light-gray-transparent-custom {
  background-color: var(--light-gray-transparent-custom);
}
.bg-danger-transparent {
  background-color: #e9484b5f;
}
.bg-red-transparent {
  background-color: var(--red-transparent-bs);
}
.bg-cool-gray-transparent {
  background-color: var(--cool-gray);
}

/*Texts*/
.text-primary-bs,
.text-primary-bs:hover {
  color: var(--primary-bs);
}

.text-warning-bs,
.text-warning-bs:hover {
  color: var(--warning-bs);
}

.text-success-bs,
.text-success-bs:hover {
  color: var(--success-bs);
}

.text-secondary-bs,
.text-secondary-bs:hover {
  color: var(--secondary-bs);
}

.text-light-gray-bs,
.text-light-gray-bs:hover {
  color: var(--light-gray);
}

.text-white {
  color: var(--white);
}
.text-grey-landing {
  color: #e1e1e1;
}
/*Form*/

.form-control {
  background-color: var(--form-background);
  color: var(--text-color-light);
  border-color: var(--border-background);
  padding: 10px 6px;
}

.form-control-mini {
  background-color: var(--form-background);
  color: var(--text-color-light);
  border-color: var(--border-background);
  width: 50px;
  text-align: center;
  padding: 10px 6px;
}

.form-control::placeholder {
  color: var(--text-color);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color);
}

.checkbox-custom input {
  display: none;
}

.checkbox-custom label {
  cursor: pointer;
  position: relative;
  padding-left: 28px;
}

.checkbox-custom label:before,
.checkbox-custom label:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  left: 0;
  border-radius: 5px;
}

.checkbox-custom label:before {
  border: 1px solid var(--text-color-light);
}

.checkbox-custom label:after {
  opacity: 0;
  background: url("./assets/icons/check.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  left: 1px;
}
.checkbox-custom-label-normal:after {
  background-color: var(--primary-bs);
}
.checkbox-custom-label-collective:after {
  background-color: var(--warning-bs);
}
.checkbox-custom-label-private:after {
  background-color: var(--success-bs);
}
.checkbox-custom
  input[type="radio"]:checked
  ~ .checkbox-custom-label-normal::before {
  background-color: var(--primary-bs);
  border-color: var(--primary-bs);
}
.checkbox-custom
  input[type="radio"]:checked
  ~ .checkbox-custom-label-collective::before {
  background-color: var(--warning-bs);
  border-color: var(--warning-bs);
}
.checkbox-custom
  input[type="radio"]:checked
  ~ .checkbox-custom-label-private::before {
  background-color: var(--success-bs);
  border-color: var(--success-bs);
}
.checkbox-custom
  input[type="checkbox"]:checked
  ~ .checkbox-custom-label-normal::before {
  background-color: var(--primary-bs);
  border-color: var(--primary-bs);
}
.checkbox-custom
  input[type="checkbox"]:checked
  ~ .checkbox-custom-label-collective::before {
  background-color: var(--warning-bs);
  border-color: var(--warning-bs);
}
.checkbox-custom
  input[type="checkbox"]:checked
  ~ .checkbox-custom-label-private::before {
  background-color: var(--success-bs);
  border-color: var(--success-bs);
}

.checkbox-custom input:checked + label::after {
  opacity: 1;
}

/*checbox circle sel*/
.checkbox-circle input {
  display: none;
}
.checkbox-circle label {
  cursor: pointer;
  position: relative;
  color: var(--text-color-light);
  padding-left: 28px;
}
.checkbox-circle label:before,
.checkbox-circle label:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  border-radius: 50%;
}
.checkbox-circle label:before {
  border: 1px solid var(--text-color-light);
}
.checkbox-circle input:checked + label::before {
  background-color: var(--primary-bs);
  border-color: var(--primary-bs);
}
.checkbox-circle label:after {
  opacity: 0;
  background: url("./assets/icons/check.png");
  background-size: 10px;
  background-color: var(--primary-bs);
  background-repeat: no-repeat;
  background-position: center;
  left: 1px;
}
.checkbox-circle input:checked + label::after {
  opacity: 1;
}
.checkbox-circle.warning input:checked + label::before {
  background-color: var(--warning-bs);
  border-color: var(--warning-bs);
}
.checkbox-circle.warning label:after {
  background-color: var(--warning-bs);
}
.checkbox-circle.success input:checked + label::before {
  background-color: var(--success-bs);
  border-color: var(--success-bs);
}
.checkbox-circle.success label:after {
  background-color: var(--success-bs);
}

/*Selected*/
.selected-custom input {
  display: none;
}
.selected-custom label {
  cursor: pointer;
  background-color: var(--light-gray);
  height: 28px;
  border-radius: 15px;
  width: 48px;
  position: relative;
  display: block;
}
.selected-custom label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--white);
  transition: all ease 300ms;
}
.selected-custom input:checked + label {
  background-color: var(--primary-bs);
}
.selected-custom input:checked + label:before {
  left: 22px;
}

/*Border*/
.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-16-top {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.rounded-12 {
  border-radius: 12px;
}

/*Table*/
.table-dark-bs {
  color: var(--light-gray);
}

.table-dark-bs thead {
  background-color: transparent;
}

.table-dark-bs th,
.table-dark-bs td {
  padding: 15px;
  color: var(--light-gray);
}

.table-striped-bs tbody,
.table-striped-bs thead th,
.table-striped-bs tbody tr,
.table-striped-bs tbody td {
  border: none !important;
}

.table-striped-bs td {
  vertical-align: middle;
}

.table-striped-bs tbody tr:nth-child(2n + 1) {
  background-color: var(--light-gray-transparent-bs);
}

.image-client {
  width: 45px;
  height: 42px;
}

.image-client img {
  position: absolute;
  top: 3px;
}

.image-client img:nth-child(1) {
  left: 0;
  z-index: 5;
}

.image-client img:nth-child(2) {
  left: 10px;
  z-index: 4;
}

.image-client img:nth-child(3) {
  left: 20px;
  z-index: 3;
}

/*Label*/
.label {
  font-size: 0.85rem;
  background-color: var(--light-gray-transparent-bs);
  padding: 2px 10px;
  border-radius: 11px;
}

.label-success-bs {
  color: var(--sucess-lighter-bs);
}

.label-inactif {
  color: var(--inactif-red);
}

.label-danger-bs {
  color: var(--danger-lighter-bs);
}

.label-light-gray-bs {
  color: var(--light-gray);
}

.label-red-bs {
  color: var(--light-gray);
  background-color: var(--red-transparent-bs);
}

.label-blue-bs {
  color: var(--light-gray);
  background-color: var(--blue-transparent-bs);
}

.label-green-bs {
  color: var(--light-gray);
  background-color: #096916;
}

.label-black-bs {
  color: var(--light-gray);
  background-color: black;
}

/*Fonts*/
.fs-32 {
  font-size: 2.28rem;
}

.fs-24 {
  font-size: 1.71rem;
}

.fs-16 {
  font-size: 1.14rem;
}

.fs-13 {
  font-size: 0.92rem;
}

.fs-12 {
  font-size: 0.85rem;
}

.fs-7 {
  font-size: 0.625rem;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

/*Buttons*/
.btn-light-gray-transparent-bs,
.btn-light-gray-transparent-bs:hover {
  background-color: var(--light-gray-transparent-bs);
  color: var(--white);
  border-color: var(--light-gray-transparent-bs);
}

.btn-primary-bs,
.btn-primary-bs:hover {
  background-color: var(--primary-bs);
  color: var(--white) !important;
  border-color: var(--primary-bs);
}

.btn-gray-transparent-bs,
.btn-gray-transparent-bs:hover {
  background-color: var(--gray-transparent-bs);
  color: var(--white);
  border-color: var(--gray-transparent-bs);
}

.btn-light-gray-transparent-custom-bs,
.btn-light-gray-transparent-custom-bs:hover {
  background-color: var(--light-gray-transparent-custom);
  color: var(--white);
  border-color: var(--light-gray-transparent-custom);
}

/*Utils*/
.pointer {
  cursor: pointer;
}

.mh-100vh {
  min-height: 100vh;
}

.separator {
  border-bottom: 1px solid var(--white-transparent);
}
.separator-white {
  border-bottom: 1px solid white;
}

.notification {
  display: block;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 12px;
  color: var(--white);
  border-radius: 50%;
  text-align: center;
  background-color: var(--primary-bs);
}

.notification-big {
  display: block;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: var(--white);
  border-radius: 50%;
  text-align: center;
  background-color: var(--primary-bs);
}

.lh-2 {
  line-height: 1.2;
}

.nav-tabs-bs {
  border-bottom: 0;
}

.nav-tabs-bs .nav-link {
  font-size: 1rem;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
  margin-right: 10px;
  color: var(--light-gray);
}

.nav-tabs-bs .nav-link.active {
  font-weight: 500;
  color: var(--white);
  background: none;
}

.gray-transparent-line {
  height: 1px;
  border: solid 1px rgba(255, 255, 255, 0.12);
}

.search-header input {
  background-color: var(--light-gray-transparent-custom);
  border: 0;
  padding: 0.4rem 1rem 0.4rem 3rem;
  border-radius: 1rem;
  color: var(--white);
}

.search-header img {
  width: 22px;
  top: 5px;
  left: 12px;
}

.invisible {
  visibility: hidden;
}

.text-right {
  text-align: right;
}

/*Content main*/
.content-main {
  width: 100%;
  padding: 3%;
}

@media (max-width: 1024px) {
  .content-main {
    max-width: 100%;
    margin-left: unset;
    padding: 0;
  }
  .content-main.collapsed {
    margin-left: unset;
  }
}

/* content iframe courses */
.content-main-iframe {
  width: 100%;
}

/* COURS */
.Oval {
  width: 36px;
  height: 36px;
  margin: 0 8px;
  padding: 9px 15px;
  background-color: #ff3085;
  border-radius: 50%;
}

.scroll-barra {
  overflow-x: scroll;
  scrollbar-color: gray transparent;
  scrollbar-width: 8px;
  box-shadow: none;
}

.card-text-groupe {
  color: #80adff !important;
}

.rectangulo-btn-eleves {
  margin: 3px 8px 8px 0px;
  padding: 3px 7.6px 5px 9.4px;
  border-radius: 8px;
}

h6,
small {
  color: var(--light-gray);
}

/*Form*/
.form-label,
.form-select,
option .form-options {
  background-color: var(--light-gray-transparent-bs);
  color: var(--light-gray);

  padding: 10px 15px;
}
.form-group.normal .gray-label {
  color: var(--light-gray);
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 2rem;
}
.form-control.textarea {
  background-color: var(--light-gray-transparent-bs);
  color: var(--light-gray);
  border-color: var(--light-gray-transparent-custom);
  padding: 10px 15px;
}

/*BONS*/
.search-bons .icon-search {
  width: 22px;
  top: 5px;
  left: 12px;
}

.search-bons .close {
  width: 22px;
  top: 5px;
  right: 15px;
}

.search-bons input {
  background-color: var(--light-gray-transparent-custom);
  border: 0;
  padding: 0.4rem 1rem 0.4rem 3rem;
  border-radius: 1rem;
  color: var(--white);
}

/*MESSAGES*/
.list-message-notifications li a.active {
  background-color: var(--light-gray-transparent-custom);
}
.list-message-notifications li a {
  border-bottom: 1px solid var(--white-transparent);
}
.list-message-notifications li a h5 {
  font-size: 16px;
  color: var(--light-gray);
}
.list-message-notifications li a .container-message-notification p {
  color: var(--light-gray);
}
.list-message-notifications li a .count-message-notification {
  text-align: right;
}
.list-message-notifications li a .count-message-notification p {
  color: var(--info-bs);
}
.list-message-notifications li a .count-message-notification p.date {
  color: var(--light-gray);
}
.list-message-notifications li .container-message-notification {
  width: calc(100% - 102px);
}
.separator-vertical-r {
  border-right: 1px solid var(--light-gray-transparent-custom);
}

.container-messages {
  border-top: 1px solid var(--light-gray-transparent-custom);
  height: 808px;
  background-color: var(--light-gray-transparent-bs);
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.text-underlinein {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: var(--light-gray);
  position: relative;
}
.text-underlinein::before,
.text-underlinein::after {
  content: " ";
  display: block;
  border: 1px solid var(--white-transparent);
  position: absolute;
  top: 10px;
  width: 35%;
  z-index: 2;
}
.text-underlinein::after {
  right: 0;
}

.text-underlinein span {
  z-index: 3;
  padding: 0 20px;
}
.message-chat .chat-bubble {
  padding: 8px 8px 6px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.36);
  border-top-left-radius: 0;
  color: var(--light-gray);
  max-width: 65%;
  margin-left: 40px;
  font-size: 16px;
}
.message-chat-recipient {
  text-align: right;
}
.message-chat-recipient .chat-bubble {
  background-color: var(--primary-bs);
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  margin-left: auto;
}
.message-chat > span {
  color: var(--white);
  padding-left: 5px;
}
.message-chat-recipient p {
  color: var(--white);
}
.message-chat > p {
  padding-left: 40px;
  font-size: 14px;
}
.message-chat .message-time {
  color: var(--light-gray);
}
.icons-btn-rounded {
  margin-right: 9px;
  padding: 7px 6px 5px;
  border-radius: 8px;
  background-color: var(--light-gray-transparent-custom);
}
.sport-features {
  width: 88px;
  padding: 16px 0 15px 0;
  border-radius: 12px;
  background-color: var(--light-gray-transparent-bs);
}
/*MESSAGES*/

/*OTHER*/
.form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: var(--light-gray);
}
.form-check-input[type="radio"]:checked {
  background-color: var(--primary-bs);
  border-color: var(--primary-bs);
}
.nav-tabs-bs.tabs--bs .nav-item button.active {
  background-color: var(--primary-bs);
  color: var(--white);
}
.point-data-status span::before {
  content: " ";
  width: 8px;
  height: 8px;
  left: 0;
  position: absolute;
  border-radius: 50%;
  top: 6px;
}
.point-data-status span,
.point-data-status + p {
  display: inline-block;
  padding-left: 15px;
  position: relative;
}
.point-data-status.point-warning span::before {
  background-color: var(--warning-bs);
}
.point-data-status.point-success span::before {
  background-color: var(--success-bs);
}
.pagination .page-link {
  color: var(--white);
  margin: 0 12px;
  padding: 9px 15px;
  display: inline-block;
  border-radius: 16px;
  border: none;
  background-color: transparent;
  line-height: 1.3;
  font-family: "Montserrat", sans-serif;
}
.pagination .page-link.active {
  background-color: #ff3085;
}
.circle-div {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.24);
  padding-top: 4px;
}
.circle-div.circle-lg {
  width: 80px;
  height: 80px;
}
.list-multimedia li,
.list-multimedia li a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.list-multimedia li a div {
  display: flex;
  border-radius: 20px;
  width: 104px;
  height: 104px;
  background-color: var(--light-gray-transparent-custom);
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.input-group-text {
  background-color: var(--light-gray-transparent-bs);
  border-color: var(--light-gray-transparent-custom);
  border-radius: 10px;
  border-right: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.input-group > .form-control,
.input-group > .form-select {
  border: 0;
}

/*INPUT CALENDAR LEFT*/
input[type="date"] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f073";
  color: #555;
  padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: 1;
}
/*INPUT CALENDAR LEFT*/

/*OTHER*/

.p-0 {
  padding: 0 !important;
}
.pt-1 {
  padding-top: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-05 {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}
.mr-1 {
  margin-right: 1rem;
}
.ml-05 {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.gap05 {
  gap: 0.5rem;
}
.bb-none {
  border-bottom: none !important;
}
.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 600px) {
  .mobile-padding {
    padding: 1em !important;
  }
  .mobile-no-padding {
    padding: 0 !important;
  }
  .second-column-mobile {
    margin-top: 2em;
  }
  .mobile-section-title {
    margin-left: 0rem !important;
    text-align: left;
    font-size: 1.8rem;
  }
  .mobile-justify-center {
    justify-content: center !important;
  }
  .mobile-mt-2 {
    margin-top: 2rem !important;
  }
  .lading-padding {
    padding: 1.5rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .lading-padding {
    border-radius: 16px;
  }
}
